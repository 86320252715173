import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the type for the state
interface ThemeState {
  theme: "light" | "dark"; // Explicitly typing the theme values
}

const initialState: ThemeState = {
  theme: "dark", // Initial theme value
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme(state, action: PayloadAction<"light" | "dark">) {
      state.theme = action.payload; // Use the payload to set the theme
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;